* {
  box-sizing: border-box;
  /* border: 1px solid red; */
}

body {
  margin: 0;
  font-family: "Almarai", sans-serif;
}
.m-0 {
  margin: 0;
}
.p-0 {
  padding: 0;
}
.font {
  font-family: "Almarai", sans-serif;
  background-color: #f9f9f9;
}
.hieght-search {
  height: 80vh;
}
.logo {
  height: 46px;
  width: 50px;
}
.login-img {
  height: 20px;
  width: 20px;
}
.nav-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}
.sub-tile {
  color: #272727;
  font-family: Almarai;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}

.slider-background {
  background: linear-gradient(173.68deg, #ffedf5 0%, #ffd5e7 100%);
  height: "296px";
  width: 294.26px;
}
.slider-background2 {
  background: linear-gradient(174.76deg, #d3deff 0%, #a8baff 100%);
  height: "296px";
  width: 294.26px;
}
.slider-background3 {
  background: linear-gradient(176.69deg, #f8f0d5 0%, #efdcab 100%);
  height: "296px";
  width: 294.26px;
}
.slider-background4 {
  background: linear-gradient(175.24deg, #b2e8f0 0%, #7bccdc 100%);
  height: "296px";
  width: 294.26px;
}
.slider-title {
  color: #915970;
  font-family: Almarai;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
.slider-text {
  color: #915970;
  font-family: Almarai;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 53px;
  text-align: center;
}
.allCard {
  /* position: relative; */
  height: 130px;
  width: 130px;
}
.categoty-card {
  height: 140px;
  width: 100%;
  border-radius: 50%;
  /* opacity: 0.3; */
}

.categoty-card-img {
  width: 80%;
  height: 80%;
  border-radius: 20px;
  margin: 10%;
}
.categoty-card-text {
  color: #555550;
  font-family: Almarai;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

/* products card style */

.card-title {
  color: #555550;
  font-family: Almarai;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.card-price {
  color: #272727;
  font-family: Almarai;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}
.card-currency {
  color: #555550;
  font-family: Almarai;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}
.card-rate {
  color: #ffc107;
  font-family: Almarai;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}
/* end of card style */
.shopping-now {
  padding: 5px;
  height: 35px;
  width: 90px;
  color: #272727;
  text-decoration: none;
  text-align: center;
  border: 1px solid #272727;
  border-radius: 14px;
}

.shopping-now:hover {
  background-color: #272727;
  border: 1px solid #272727;
  color: #f9f9f9;
  border-radius: 14px;
  cursor: pointer;
}

/* discount setion */
.discount-backcolor {
  height: 130px;
  border-radius: 8px;
  background: radial-gradient(circle, #767676 0%, #4d4f50 100%, #494c4d 100%);
}
.discount-title {
  color: #ffffff;
  font-family: Cairo;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px;
}
.dicount-img {
  max-height: 130px;
  max-width: 80%;
}
/* end discount */

/* foter */
.footer-background {
  background-color: #ffffff;
}

.footer {
  width: 100%;
}
.footer-shroot {
  color: #979797;
  font-family: Almarai;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
}

.footer-phone {
  color: #979797;
  font-family: "Almarai";
  font-size: 13px;
  font-weight: bold;
  text-align: right;
  cursor: pointer;
}
/* end footer */

/* card filter style */
.search-count-text {
  color: #979797;
  font-family: Almarai;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.card-filter {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  width: 170px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-filter-item {
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
}
.card-filter-item:hover {
  background-color: #272727;
  color: #ffffff;
}
/* end card filter */

/* products details page */
.product-gallary-card {
  height: 470px !important;
  width: 370px;
  border-radius: 29px;
  background-color: #ffffff;
}

.cat-text {
  color: #979797;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}

.cat-title {
  color: #555550;
  font-family: Almarai;
  font-size: 14px;
  font-weight: normal;
  text-align: right;
}

.cat-rate {
  color: #ffc107;
  font-family: "Almarai";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}

.barnd-text {
  color: #555550;
  font-family: "Almarai";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.color {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.product-description {
  color: #555550;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  text-align: right;
}
.product-price {
  height: 46px;
  width: 150px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 9px;
  color: #555550;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  background-color: #ffffff;
}

.product-cart-add {
  cursor: pointer;
  text-align: center;
  height: 45px;
  border-radius: 9px;
  border: none;
  background-color: #272727;
  color: #ffffff;
  font-family: "Almarai";
  font-size: 14px;
  font-weight: normal;
}
.product-cart-add:hover {
  color: #bdbaba !important;
}
/* products gallary slide style */
.image-gallery-slide {
  width: 372px;
  height: 470px;
  border-radius: 29px;
}
/* end gallary */
/* end products page */

/* pagination */
.page-link {
  background-color: #f9f9f9 !important;
  color: #272727 !important;
  border-radius: 10px !important;
  border: solid 1px 272727 !important;
}
.page-link:hover {
  background-color: #272727 !important;
  color: white !important;
  border: solid 1px 272727 !important;
}
/* end pagination */

/* category header */
.cat-header {
  height: 100%;
  background-color: white;
}

.cat-text-header {
  color: #979797;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  padding-top: 10px;
  margin-left: 17px;
  cursor: pointer;
}
.cat-text-header:hover {
  color: #555550;
}

/* end categoery header */

/* login and register style */

.user-input {
  box-sizing: border-box;
  height: 37px;
  width: 375px;
  border: 1px solid #979797;
  border-radius: 8px;
}
.title-login {
  color: #555550;
  font-family: Almarai;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}
.btn-login {
  height: 46px;
  width: 374px;
  border-radius: 9px;
  border: none;
  color: white;
  background-color: #272727;
}
.btn-login:hover {
  color: rgb(206, 204, 204);
}

/* end login and register */

/* cart page */
.cart-title {
  color: #555550;
  font-family: Almarai;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}
.cart-item-body {
  height: 229px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}

.cart-item-body-admin {
  height: 130px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}

.copon-input {
  box-sizing: border-box;
  height: 46px;
  width: 90%;
  border: 1px solid #979797;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: normal;
}
.copon-btn {
  cursor: pointer;
  border: none;
  height: 46px;
  padding-top: 5px;
  border-radius: 5px !important;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px !important;
  border-radius: 0 !important;
  background-color: #272727;
  color: #ffff !important;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: normal;
}

.cart-checkout {
  height: 200px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}

/* cart end */

/* admin style  */
.sidebar {
  height: 660px;
  width: 100%;
  border-radius: 21px;
  background-color: #ffffff;
}

.admin-side-text {
  color: #272727;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.admin-side-text:hover {
  background-color: #272727;
  color: white;
  font-size: 14px;
}
.admin-content-text {
  color: #555550;
  font-family: Almarai;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}
.item-delete-edit {
  color: #979797;
  font-family: "Almarai";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
  cursor: pointer;
}

.text-form {
  color: #979797;
  font-family: "Almarai";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.input-form {
  height: 37px;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.input-form-area {
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.select {
  box-sizing: border-box;
  height: 40px;
  color: #979797;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;
}
.btn-save {
  border: none;
  color: white;
  height: 46px;
  width: 150px;
  border-radius: 9px;
  background-color: #272727;
}
.btn-save:hover {
  color: rgb(164, 164, 164);
}
.btn-a {
  border: none;
  color: white;
  height: 45px;
  border-radius: 9px;
  background-color: #272727;
}
.btn-a:hover {
  color: rgb(164, 164, 164);
}
/* end admin  */

/* rates  */

.rate-container {
  height: 100%;
  width: 100%;
  padding-bottom: 12px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}
.rate-count {
  color: #979797;
  font-family: "Almarai";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
}
.rate-name {
  color: #555550;
  font-family: "Almarai";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}
.rate-description {
  color: #555550;
  font-family: "Almarai";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}
/* end rate */

/* user order */
.user-order {
  height: 100%;
  width: 100%;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}
.order-title {
  color: #272727;
  font-family: "Almarai";
  font-size: 16px;
  font-weight: bold;
}

.stat {
  color: #979797;
  font-family: Cairo;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
}
/* end user order */

/* user Address */

.user-address-card {
  height: 215px;

  width: 100%;
  border-radius: 7px;

  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}
.btn-add-address {
  border: none;
  height: 50px;
  width: 100%;
  color: white;
  border-radius: 9px;
  background-color: #272727;
}

/* user address */

/* user card data */
.user-data {
  height: 270px;
  width: 100% i !important;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}
/* end user data */

/* filter side style */
.filter-title {
  color: #555550;
  font-family: "Almarai";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.filter-sub {
  color: #555550;
  font-family: "Almarai";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}
/* end filter */
#upload-photo {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/*  
update some bootstrap style 

*/
.dropdown-item {
  color: #555550;
  font-family: "Almarai";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right !important;
}

#basic-nav-dropdown {
  color: #f1f1ea !important;
  font-family: "Almarai";
  letter-spacing: 0;
  line-height: 19px;
  text-align: right !important;
  font-size: 20px !important;
  margin: -9px 0 -3px 0 !important;
}

.dropdown-toggle::after {
  margin: 0 7px 0 0;
}

/* 
  update some bootstrap style .badge
*/

.badge.bg-danger {
  font-size: 13px !important;
  font-weight: bold !important;
}

/* 
  cart-item-body image
*/

.cart-item-coverImg {
  margin: 0 0 0 19px !important;
  height: 100% !important;
  border-radius: 19px;
  padding: 9px 5px;
}
